import { ClientLogger } from "bernie-client";
import { SystemEvent, SystemEventLevel } from "bernie-logger";
import { ExtendedContextStore } from "src/typings/flexFramework/FlexDefinitions";

export const getFlightsCacheWarmingRequestPayload = (context: ExtendedContextStore): string => {
  const cacheWarmingRequestData = {
    context: {
      locale: context.locale,
      currency: context.currency,
      device_context: {
        device_user_agent_id: context.deviceId,
        device_type: context.deviceInformation?.type,
      },
    },
    pointOfSaleContext: {
      site_id: context.site.id,
      legacy_point_of_sale: {
        tpid: context.site.tpid,
        eapid: context.site.eapid,
      },
    },
  };
  return JSON.stringify(cacheWarmingRequestData);
};

export const triggerFlightsCachingWarming = async (context?: ExtendedContextStore) => {
  if (context) {
    const logger = ClientLogger.getLoggerWithIdentifier("processFlightsCacheWarming");
    try {
      const flightsCachingWarmingRequestPayload = getFlightsCacheWarmingRequestPayload(context);

      await fetch(`${window.location.origin}/flights-search-cache-hydrate`, {
        method: "POST",
        body: flightsCachingWarmingRequestPayload,
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
        },
      });
    } catch (error) {
      logger.logEvent(new SystemEvent(SystemEventLevel.ERROR, "FLIGHTS_CACHE_WARMING_API_ERROR"), error);
    }
  }
};
