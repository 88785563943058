import { DateFieldData, LodgingFormEvent } from "@shared-ui/lodging-form";
import { useLocalization } from "@shared-ui/localization-context";

export const useDateFieldData = (isVrbo = false, maxDateRange = 0): DateFieldData => {
  const { formatText } = useLocalization();
  return {
    startId: "hotels-check-in",
    startLabel: formatText("datePicker.fromDate.label"),
    startName: "startDate",
    startPlaceholder: formatText("datePicker.dateInput.placeholder"),
    startInvalid: "",
    endId: "hotels-check-out",
    endLabel: formatText("datePicker.toDate.label"),
    endName: "endDate",
    endPlaceholder: formatText("datePicker.dateInput.placeholder"),
    endInvalid: "",
    buttonText: formatText("datePicker.doneHeader"),
    daysBookableInAdvance: 500,
    midnightBookingOffset: 4,
    __typename: "LodgingFormDateField",
    validations: getValidations(isVrbo, maxDateRange, formatText),
  };
};

export const getValidations = (
  isVrbo: boolean,
  maxDateRange: number,
  formatText: (key: string, ...args: any[]) => string
) => {
  const validationList: DateFieldData["validations"] = [
    {
      __typename: "StartDateRequiredValidation",
      error: formatText("wizard.datePicker.warning.invalidFromDate.text"),
      events: [LodgingFormEvent.ON_CHANGE, LodgingFormEvent.ON_SUBMIT],
    },
    {
      __typename: "EndDateRequiredValidation",
      error: formatText("wizard.datePicker.warning.invalidToDate.text"),
      events: [LodgingFormEvent.ON_CHANGE, LodgingFormEvent.ON_SUBMIT],
    },
    {
      __typename: "DateOrderValidation",
      error: formatText("wizard.datePicker.warning.invalidToDate.text"),
      events: [LodgingFormEvent.ON_LOAD, LodgingFormEvent.ON_CHANGE, LodgingFormEvent.ON_SUBMIT],
    },
  ];

  if (!isVrbo) {
    const maxDateRangeValidation: any = {
      __typename: "MaxDateRangeValidation",
      error:
        !isNaN(maxDateRange) && maxDateRange > 0
          ? formatText("wizard.datePicker.warning.dynamicDateRangeOverLimit", maxDateRange)
          : formatText("wizard.datePicker.warning.dateRangeOverLimit"),
      maxDateRange: !isNaN(maxDateRange) && maxDateRange > 0 ? maxDateRange : 28,
      events: [LodgingFormEvent.ON_LOAD, LodgingFormEvent.ON_CHANGE, LodgingFormEvent.ON_SUBMIT],
    };
    const newValidationsList: DateFieldData["validations"] = [...validationList, maxDateRangeValidation];
    return newValidationsList;
  }

  return validationList;
};
