import { cropResizeSrc } from "components/shared/BlossomImage/BlossomImage";

export const optimizeHeroImageUrl = (imageUrl: string, isMobile?: boolean, isVrbo?: boolean): string => {
  let dimensions;

  if (isMobile) {
    dimensions = !isVrbo ? { width: 450, height: 280 } : { width: 360, height: 224 };
  } else if (isVrbo) {
    dimensions = { width: 1920, height: 580 };
  } else {
    dimensions = { width: 1920, height: 480 };
  }

  return cropResizeSrc(imageUrl, dimensions, "medium", false);
};

export const optimizeHotwireHeroImageUrl = (imageUrl: string, isMobile?: boolean): string => {
  return isMobile
    ? cropResizeSrc(imageUrl, { width: 575, height: 431 }, "medium")
    : cropResizeSrc(imageUrl, { width: 1176, height: 504 }, "medium");
};

export const imgDimension = (imgHeight: string): any => {
  switch (imgHeight) {
    case "usf-mobile-image-height": {
      return "13.125rem";
    }
    case "zero-min-height": {
      return "0";
    }
    default: {
      return undefined;
    }
  }
};
