import { ExtendedContextStore } from "src/typings/flexFramework/FlexDefinitions";

export const START_DATE_REF_NAME = "rateDate";
export const END_DATE_REF_NAME = "rateEndDate";
export const queryBuilder = (startDate: Date, endDate: Date, context: ExtendedContextStore, additionalList: string) => {
  const formattedStartDate = `${startDate.getFullYear()}-${startDate.getMonth() + 1}-${startDate.getDate()}`;
  const formattedEndDate = `${endDate.getFullYear()}-${endDate.getMonth() + 1}-${endDate.getDate()}`;

  const url =
    "?chkin=" +
    formattedStartDate +
    "&chkout=" +
    formattedEndDate +
    "&selected=" +
    context.searchContext.location.id +
    "&expediaPropertyId=" +
    context.searchContext.location.id +
    "&regionId=" +
    context.searchContext.location.parentId +
    "&propertyName=" +
    context.searchContext.location.defaultName.replaceAll(" ", "+") +
    "&destination=" +
    encodeURIComponent(context.searchContext.location.parent.extendedName.replace(" ", "+")) +
    additionalList;

  return url;
};
