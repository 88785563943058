import { TravelersData } from "@shared-ui/lodging-form";
import { useLocalization } from "@shared-ui/localization-context";

export const useTravelersFieldData = (isVrbo = false): TravelersData => {
  const { formatText } = useLocalization();
  return {
    __typename: "LodgingFormTravelers",
    label: formatText("wizard.roomPicker.travelers.title"),
    travelersSummary: {
      __typename: "TravelersSummary",
      roomsAndGuests: "wizard.roomPicker.roomsGuests",
    },
    roomPicker: {
      __typename: "RoomPicker",
      accessibility: "",
      toolBarIconLabel: formatText("wizard.roomPicker.closeButton.a11yLabel"),
      toolBarTitle: formatText("wizard.roomPicker.title"),
      addRoomText: getAddRoomButtonText(isVrbo, formatText),
      doneLabel: formatText("datePicker.doneHeader"),
      roomAddedLiveAnnounce: formatText("wizard.roomPicker.roomAdded.liveAnnounce", "{0}"),
      room: {
        __typename: "RoomField",
        roomTitleAriaLabel: formatText("wizard.roomPicker.room.a11yLabel", "{0}", "{1}"),
        roomTitle: formatText("wizard.roomPicker.room.title", "{0}"),
        adultsStepInput: {
          __typename: "StepInput",
          title: formatText("wizard.roomPicker.adults.text"),
          increaseText: formatText("wizard.roomPicker.increaseAdultsInRoom.text", "{0}"),
          decreaseText: formatText("wizard.roomPicker.decreaseAdultsInRoom.text", "{0}"),
          caption: null,
        },
        childrenStepInput: {
          __typename: "StepInput",
          title: formatText("wizard.roomPicker.children.text"),
          increaseText: formatText("wizard.roomPicker.increaseChildrenInRoom.text", "{0}"),
          decreaseText: formatText("wizard.roomPicker.increaseInfantInRoom.text", "{0}"),
          caption: formatText("wizard.roomPicker.children.caption"),
        },
        removeRoomButtonLabel: formatText("wizard.roomPicker.removeRoomButton.label"),
        removeRoomButtonAriaLabel: formatText("wizard.roomPicker.removeRoomButton.a11yLabel", "{0}", "{1}"),
        roomRemovedLiveAnnounce: formatText("wizard.roomPicker.removeRoomButton.a11yLabel", "{0}"),
        childrenAges: {
          __typename: "ChildrenAges",
          selectChildAgeLabel: formatText("wizard.roomPicker.childAgeInput.label", "{0}"),
          youngestChildAgeText: formatText("wizard.roomPicker.infants.firstyear"),
          childAgeUpdatedLiveAnnounce: formatText("wizard.roomPicker.childUpdated.liveAnnounce", "{0}", "{1}"),
        },
      },
    },
  };
};

export const getAddRoomButtonText = (isVrbo: boolean, formatText: (key: string, ...args: any[]) => string) => {
  if (!isVrbo) {
    return formatText("wizard.roomPicker.addRoomButton.label");
  }
  return "";
};
