import * as React from "react";

import { ExtendedContextStore } from "src/typings/flexFramework/FlexDefinitions";

import { Amenities } from "@shared-ui/retail-product-amenities";
import { ProductBexApiWrapper } from "components/shared/BexApiWrapper/ProductBexApiWrapper";

const WrappedProductAmenities = ProductBexApiWrapper(Amenities);

export const AmenitiesModule = ({ context }: { context: ExtendedContextStore }) => (
  <WrappedProductAmenities context={context} />
);
